html {
	scroll-behavior: smooth;
}

html,
body {
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

@media screen and (min-width: 1200px) {
	.MuiContainer-root {
		max-width: 1150px;
	}
	.serviceLogoContainer {
		max-width: calc(1100px * 0.48);
	}
	.slogansContainer {
		max-width: calc(1100px * 0.48);
	}
}

@media screen and (min-width: 1350px) {
	.MuiContainer-root {
		max-width: 1250px;
	}
	.serviceLogoContainer {
		max-width: calc(1200px * 0.48);
	}
	.slogansContainer {
		max-width: calc(1200px * 0.48);
	}
}

@media screen and (min-width: 1450px) {
	.MuiContainer-root {
		max-width: 1350px;
	}
	.serviceLogoContainer {
		max-width: calc(1300px * 0.48);
	}
	.slogansContainer {
		max-width: calc(1300px * 0.48);
	}
}

@media screen and (min-width: 1700px) {
	.MuiContainer-root {
		max-width: 1600px;
	}
	.serviceLogoContainer {
		max-width: calc(1550px * 0.48);
	}
	.slogansContainer {
		max-width: calc(1550px * 0.48);
	}
}

.MuiTelInput-FlagImg {
	width: 24px;
	height: 20px;
}

#cc-main {
	--cc-btn-primary-bg: #dc3c54;
	--cc-btn-primary-border-color: #dc3c54;
	--cc-btn-primary-hover-bg: #b73145;
	--cc-btn-primary-hover-border-color: #b73145;
}

.grecaptcha-badge {
	z-index: 9999 !important;
}
